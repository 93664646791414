import utils from './utils';

interface StorageItem<T> {
	value: T;
	timestamp: number;
	expiration?: number;
}

interface StorageMetrics {
	size: number;
	lastCleanup: number;
}

class LocalStorageManager {
	private readonly MAX_STORAGE = 5 * 1024 * 1024; // 5MB in bytes
	private readonly HIGH_WATER_MARK = 0.7; // 70% capacity
	private readonly TARGET_USAGE = 0.5; // 50% capacity
	private readonly CLEANUP_INTERVAL = 5 * 60 * 1000; // 5 minutes
	private readonly METRICS_KEY = '__storage_metrics__';
	private readonly FEATURE_FLAG_PREFIX = 'featureFlag_';
	private metrics: StorageMetrics;
	private sizeCache: Map<string, number>;

	constructor() {
		this.sizeCache = new Map();
		this.metrics = this.loadMetrics();
		this.performMaintenance();
	}

	/**
	 * Load or initialize storage metrics
	 */
	private loadMetrics(): StorageMetrics {
		try {
			const metricsStr = localStorage.getItem(this.METRICS_KEY);
			return metricsStr ? JSON.parse(metricsStr) : { size: 0, lastCleanup: 0 };
		} catch {
			return { size: 0, lastCleanup: 0 };
		}
	}

	/**
	 * Save current metrics to storage
	 */
	private saveMetrics(): void {
		localStorage.setItem(this.METRICS_KEY, JSON.stringify(this.metrics));
	}

	/**
	 * Calculate the size of a key-value pair in bytes
	 */
	private calculateItemSize(key: string, value: string): number {
		return (key.length + value.length) * 2; // UTF-16 encoding
	}

	/**
	 * Update the cached size when adding/removing items
	 */
	private updateSize(key: string, value?: string): void {
		const oldSize = this.sizeCache.get(key) || 0;
		this.metrics.size -= oldSize;

		if (value) {
			const newSize = this.calculateItemSize(key, value);
			this.sizeCache.set(key, newSize);
			this.metrics.size += newSize;
		} else {
			this.sizeCache.delete(key);
		}

		this.saveMetrics();
	}

	/**
	 * Perform maintenance tasks (cleanup expired items and manage storage size)
	 */
	private performMaintenance(): void {
		const now = Date.now();

		// Only run cleanup if enough time has passed since last cleanup
		if (now - this.metrics.lastCleanup > this.CLEANUP_INTERVAL) {
			this.cleanExpiredItems();
			this.manageStorageSize();
			this.metrics.lastCleanup = now;
			this.saveMetrics();
		}
	}

	/**
	 * Remove oldest items until storage is below target usage
	 */
	private manageStorageSize(): void {
		if (this.metrics.size > this.MAX_STORAGE * this.HIGH_WATER_MARK) {
			const items: { key: string; timestamp: number }[] = [];

			// Convert Map keys to array for compatibility
			const keys = Array.from(this.sizeCache.keys());

			// Collect items that can be removed (non-system items)
			keys.forEach((key) => {
				if (key !== this.METRICS_KEY && !key.startsWith(this.FEATURE_FLAG_PREFIX)) {
					const item = this.getRaw(key);
					if (item) {
						items.push({ key, timestamp: item.timestamp });
					}
				}
			});

			// Sort by timestamp (oldest first)
			items.sort((a, b) => a.timestamp - b.timestamp);

			// Remove oldest items until we're below target usage
			while (this.metrics.size > this.MAX_STORAGE * this.TARGET_USAGE && items.length > 0) {
				const oldest = items.shift();
				if (oldest) {
					this.remove(oldest.key);
				}
			}
		}
	}

	/**
	 * Clean expired items from storage
	 */
	private cleanExpiredItems(): void {
		const now = Date.now();
		const keysToRemove: string[] = [];

		// Convert Map keys to array for compatibility
		Array.from(this.sizeCache.keys()).forEach((key) => {
			if (key !== this.METRICS_KEY) {
				const item = this.getRaw(key);
				if (item?.expiration && item.expiration < now) {
					keysToRemove.push(key);
				}
			}
		});

		keysToRemove.forEach((key) => this.remove(key));
	}

	private getRealKey(key: string): string {
		return key.replace(':uid', utils.uid);
	}

	/**
	 * Set an item in localStorage with optional expiration
	 */
	set<T>(key: string, value: T, duration?: number): void {
		const item: StorageItem<T> = {
			value,
			timestamp: Date.now(),
			expiration: duration ? Date.now() + duration : undefined,
		};

		const realKey = this.getRealKey(key);

		try {
			const serializedItem = JSON.stringify(item);
			localStorage.setItem(realKey, serializedItem);
			this.updateSize(realKey, serializedItem);
			this.performMaintenance();
		} catch (error) {
			console.error('Error setting localStorage item:', error);
			throw error;
		}
	}

	/**
	 * Get an item from localStorage with type safety
	 */
	get<T>(key: string, fallback?: T): T | null {
		try {
			const item = this.getRaw<T>(key);

			if (!item) return fallback ?? null;
			if (item.expiration && item.expiration < Date.now()) {
				this.remove(key);
				return fallback ?? null;
			}

			return item.value;
		} catch (error) {
			console.error('Error getting localStorage item:', error);
			return fallback ?? null;
		}
	}

	/**
	 * Get timestamp a key was added to localStorage
	 */
	getTimestamp(key: string): number | null {
		const item = this.getRaw(key);
		return item ? item.timestamp : null;
	}

	/**
	 * Get raw storage item without parsing value
	 */
	getRaw<T>(key: string): StorageItem<T> | null {
		const item = localStorage.getItem(this.getRealKey(key));
		if (!item) return null;
		try {
			return JSON.parse(item) as StorageItem<T>;
		} catch {
			return null;
		}
	}

	/**
	 * Remove an item from localStorage
	 */
	remove(key: string): void {
		localStorage.removeItem(this.getRealKey(key));
		this.updateSize(key);
	}

	/**
	 * Clear all items from localStorage
	 */
	clear(): void {
		localStorage.clear();
		this.sizeCache.clear();
		this.metrics.size = 0;
		this.saveMetrics();
	}

	/**
	 * Get current storage usage percentage
	 */
	getUsagePercentage(): number {
		return (this.metrics.size / this.MAX_STORAGE) * 100;
	}
}

export default LocalStorageManager;
